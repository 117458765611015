import { doc, getDoc, setDoc } from '@firebase/firestore'
import { useUserStore } from '~~/store/user'
import { CameraRoll } from '~~/types/types'
import { logEvent } from '~~/composables/useAnalytics'
import { createCollection } from './useFirebase'
import {getCameraRoll} from "~/composables/getCameraRoll";
import {storeToRefs} from "pinia";
import {getFunctions, httpsCallable} from "@firebase/functions";

export const joinCameraRoll = async (token: string, dataNotFoundCb = ()=>{}) => {
  const userStore = useUserStore()

  const col = createCollection<CameraRoll>('camera-rolls')
  const docRef = doc(col, token as string)
  const snap = await getDoc(docRef)

  const rollData = snap.data()
  if (rollData) {
    if (rollData.members.map((m) => m.path).includes(userStore.userRef.path)) {
      return
    }
    rollData.members.push(userStore.userRef)
    logEvent('Guest App usage', 'Joined Camera Roll', docRef.id)

    await setDoc(docRef, rollData)
  } else {
    dataNotFoundCb()
  }
}

export const addToCameraRollAndForward = async(targetRollId: string) => {
    const userStore = useUserStore()
    const router = useRouter()

    if (!targetRollId) {
        throw new Error('No token found in the query')
    }

    await joinCameraRoll(targetRollId as string)

    const nextUrl = await userStore.homepage()
    if (nextUrl) {
        router.push(nextUrl)
    }
}

export const leaveCameraRoll = async (token: string, dataNotFoundCb = ()=>{}) => {
  const userStore = useUserStore()

  const col = createCollection<CameraRoll>('camera-rolls')
  const docRef = doc(col, token as string)
  const snap = await getDoc(docRef)
  const rollData = snap.data()
  if (rollData) {
    if (rollData.members.map((m) => m.path).includes(userStore.userRef.path)) {
      // Filter out the current user from the members array
      rollData.members = rollData.members.filter(m => m.path !== userStore.userRef.path);
      logEvent('Guest App usage', 'Left Camera Roll', docRef.id)

      await setDoc(docRef, rollData)
    }
  } else {
    dataNotFoundCb()
  }
}


export const addCameraRollToUserAndForward = async (targetRollId: string) => {
    const cameraRoll = ref<CameraRoll>()
    const router = useRouter()

    const targetRoll = await getCameraRoll(targetRollId.trim())
    cameraRoll.value = targetRoll.data() as CameraRoll
    const userStore = useUserStore()
    const { userRef } = storeToRefs(userStore)

    // see if the user is already part of this camera roll
    if (cameraRoll.value?.members?.find((m) => m.path == userRef.value.path)) {
      console.debug(`User is already part of this camera roll, so we redirect him to the camera-roll ${targetRollId}`)
      router.push(`/camera-roll/${targetRollId}`)
    } else {
      await addToCameraRollAndForward(targetRollId)
    }
}

export const reassignCameraRolls = (from: string, to: string) => {
    const functions = getFunctions()
    const reassignCameraRollsFunction = httpsCallable(functions, 'reassignCameraRolls')
    reassignCameraRollsFunction({ from: 'users/' + from, to: 'users/' + to })
}
