import { createCollection } from "~/composables/useFirebase";
import { CameraRoll } from "~/types/types";
import { doc, getDoc } from "@firebase/firestore";

export const getCameraRoll = async (targetRollId: string) => {
  console.debug(`Getting camera-roll with id ${targetRollId}`)
  const col = createCollection<CameraRoll>('camera-rolls')
  const docRef = doc(col, targetRollId as string)
  const roll = await getDoc(docRef)

  if (!roll.exists()) {
    throw new Error(`Invalid Camera Roll ${targetRollId}`)
  }
  return roll
}
